@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}


/* width */
::-webkit-scrollbar {
	width: 7px;
	height: 2px;
	border-radius: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: #c4c3c3; 
	border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: #555; 
  }

.g-bg-secondary-400{
	background: #40d4ee;
}
.g-bg-secondary-500{
	background: #38bdd4;
}
.g-bg-secondary-500:hover{
	background: #2f9caf;
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}


/* App.css */

/* .register-form{
	margin-top: 5%;
} */
  
  label {
	display: block;
	margin-bottom: 0.5rem;
	font-weight: 500;
  }
  
  input {
	padding: 0.5rem;
	border: 1px solid #ccc;
	border-radius: 5px;
  }
  
  /* span {
	color: red;
	font-size: 0.875rem;
  } */


  .bg-brand-700{
	background-color:#353061
  }
  .bg-brand-600{
	background-color:#4a4485 !important
  }

  .bg-brand-500{
	background-color:#59519E
  }

  .bg-brand-400{
	background-color:#6057ad
  }
  .bg-brand-300{
	background-color:#6a61bd
  }
  .bg-brand-200{
	background-color:#6b62c2
  }

  .chakra-input{
	border-color: rgb(203 213 224) !important;
  }
  .chakra-select{
	border-color: rgb(203 213 224) !important;
  }
  
  
  .chakra-modal__header{
	background: #b6b5b515 !important;
    padding: 6px 10px !important;
  }

  .g-card-top-bg{
	background-color: rgb(56 189 212 / 8%);
    padding :5px;
    border-radius: 10px;
  }
  
  .g-card-avtar{
	width: 100px;
	height: 80px;
  }

  .g-box-shadow{
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 1px 16px #00000015;
	border-radius: 12px;
  }

  .g-card-button-success{
	background: #179F661C 0% 0% no-repeat padding-box;
	border: 1px solid #169D65;
	border-radius: 12px;
	opacity: 1;
	padding: 5px 10px;
    font-size: 13px;
	margin: 0px 3px;
	font-weight: 500;
	letter-spacing: 0px;
	color: #169D65;
  }

  .g-card-button-primary{
	background: #59519E1F 0% 0% no-repeat padding-box;
	border: 1px solid #59519E;
	border-radius: 12px;
	opacity: 1;
	padding: 5px 10px;
    font-size: 13px;
	margin: 0px 3px;
	font-weight: 500;
	letter-spacing: 0px;
	color: #59519E;
  }

  .g-card-button-referrral{
	background: #E3E3E31C 0% 0% no-repeat padding-box;
	border: 1px solid #575757;
	border-radius: 10px;
	opacity: 1;
	padding: 5px 10px;
    font-size: 13px;
	margin: 0px 3px;
  }

  .g-scroll{
	white-space: nowrap;
    overflow: auto;
	}

/* Hide the default file input */
input[type="file"] {
	display: none;
  }
  
  /* Style the custom button */
  .file-upload-button {
	display: inline-block;
	padding: 10px 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #f8f9fa;
	color: #333;
	font-size: 16px;
	cursor: pointer;
	text-align: center;
	transition: background-color 0.3s, border-color 0.3s;
  }



  .g-preview-image{
	border: 1px solid #ccc;
    padding: 10px;
    border-radius: 15px;
    width: 120px;
    height: 120px;
    margin-right: 20px;
  }
  

  .is-mobile-logout{
	display: none;
  }

  .g-medicine-input{
	border: none;
	background-color: #ADD8E6; /* Light blue background color */
	border-radius: 4px;
  }


  .login-bg-gradient{
	background-image: linear-gradient(-20deg, #38bdd4 10%, #59519e 50%);
  }

  .react-calendar__tile:disabled {
    background-color: #f0f0f07d !important;
}


  /* Hide on screens 768px and wider */
@media (min-width: 1000px) {
	.g-bottom-navbar {
	  display: none;
	}
  }
  
  /* Show on screens less than 768px */
  @media (max-width: 991px) {
	.is-mobile-logout{
		display: initial;
	}
	.g-preview-image{
		border: 1px solid #ccc;
		padding: 10px;
		border-radius: 15px;
		width: 100%;
    height: 100%;
		margin-right: 10px;
	  }
	  
	.g-bottom-navbar {
	  display: block;
	}
	.g-card-button-success{

		padding: 4px 10px;
		font-size: 12px;
		margin: 0px 2px;
	  }
	
	  .g-card-button-primary{
		padding: 3px 7px;
		font-size: 12px;
		margin: 0px 2px;
	  }
	
	
	  .g-card-button-referrral{
		padding: 3px 7px;
		font-size: 12px;
		margin: 0px 2px;
	  }
  }

  